import type { DataObject } from 'o365-dataobject';

import { markRaw } from 'vue';
import { logger, Debouncer } from 'o365-utils'

export interface DataColumnOptions {
    field?: string; name?: string; column?: string; colId?: string;
    type?: string;
    distinct?: string;
    order?: number;
    suppressNavigable?: boolean;
    hide?: boolean;
    editable?: boolean;
    cellEditor?: object;
    filter?: object | boolean;
    filterdropdown?: object;
    filterParams?: FilterParams,
    headerName?: string;
    headerTitle?: string;
    class?: any;
    editorClass?: any;
    cellStyle?: any;
    cellStyles?: any[]; // TODO: Review
    cellRenderer?: object | string;
    cellRendererParams?: object;
    cellEditorParams?: object;
    sortable?: boolean;
    sort?: string;
    width?: number;
    minWidth?: number;
    maxWidth?: number;
    resizable?: boolean;
    suppressMovable?: boolean;
    pinned?: string;
    lockPinned?: boolean;
    parentGroupId?: string;
    groupAggregate?: string;
    groupPathMode?: boolean;
    summaryAggregate?: string | ((pData: any[]) => Promise<any>);
    summaryRound?: number | string;
    summaryFormat?: string;
    dblclickHandler?: ((e: MouseEvent, ...args: any) => void);
    left?: number;
    flexWidth?: string;
    menu?: boolean;
    sortOrder?: number;
    defaultSortDirection?: string;
    format?: string;
    openOnIconClick?: boolean;
    cellTitle?: Function | string,
    cellRenderSlot?: Function;
    cellEditorSlot?: Function;
    contextmenuTopSlot?: Function;
    contextmenuBottomSlot?: Function;
    bulkUpdate?: boolean;
    bulkUpdateEditorSlot?: Function;
    bulkUpdateApplyFunction?: Function;
    getCopyValue?: (item: any, index: number) => string;
    getPasteValue?: (item: any, index: number) => string;
    overwriteCopy?: Function;
    overwritePaste?: Function;
    headerTextSlot?: Function;
    aggregateSlot?: Function;
    filterSlot?: Function;
    overlaySlot?: Function,
    summarySlot?: Function,
    classFn?: Function;
    groupAggregateFn?: Function;
    suppressSelection?: boolean;
    fallbackValue?: String;
    lines?: Number;
    colspan?: Number;
    filterField?: string;
    sortField?: string;
    singleClickEdit?: boolean;
    headerMenuSlot?: Function;
    disableResize?: boolean,
    disableMenu?: boolean,
    disableDistinct?: boolean;
    utc?: boolean;
    required?: boolean;
    autoHeight?: boolean;
    groupPathField?: string;
    groupPathReplacePlaceholder?: (pPathId: string | number) => Promise<string>;
    exportMetaData?: any,
    headerClass?: any,
    boundFields: string[],
}

export default class DataColumn {
    colId: string;
    field: string;
    type: string;
    headerName: string;
    headerTitle: string;
    editable: boolean | ((pRow: any) => boolean);
    order: number;
    suppressNavigable: boolean;
    distinct: { columns: string[] };
    filter: object | boolean | string;
    filterField?: string;
    sortField?: string;
    filterDropdown: object | string;
    filterParams?: FilterParams;
    cellClass: any;
    cellStyle: any;
    cellStyles: any[];
    cellEditor: object | string;
    cellRenderer: object | string;
    cellRendererParams: object;
    cellEditorParams: object;
    sortable: boolean;
    sort: string;
    minWidth: number;
    maxWidth: number;
    resizable: boolean;
    suppressMovable: boolean;
    lockPinned: boolean;
    parentGroupId: string;
    groupAggregate?: string;
    summaryAggregate?: string | ((pData: any[]) => Promise<any>);
    summaryRound?: number;
    summaryFormat?: string;
    dblclickHandler: ((e: MouseEvent, ...args: any) => void) | null;
    left: number;
    flexWidth: number;
    menu: boolean;
    sortOrder: any; // TODO: check if duplicate of sort
    format: string;
    defaultSortDirection: string | null = null;
    cellTitle: Function | string;
    cellRenderSlot: Function;
    cellEditorSlot: Function;
    bulkUpdate: boolean;
    contextmenuTopSlot?: Function;
    contextmenuBottomSlot?: Function;
    bulkUpdateEditorSlot: Function;
    bulkUpdateApplyFunction: Function;
    unbound: boolean;
    propertyChanges: object;
    getCopyValue: (item: any, index: number) => string;
    getPasteValue: (item: any, index: number) => string;
    overwriteCopy?: Function;
    overwritePaste?: Function;
    headerTextSlot: Function;
    filterSlot: Function;
    hideFromChooser: boolean = false; // When true, hides the column from column chooser
    editorClass: any;
    aggregateSlot: Function;
    overlaySlot?: Function;
    classFn: Function;
    groupAggregateFn: Function;
    groupPathField?: string;
    groupPathMode?: boolean;
    groupPathReplacePlaceholder?: (pPathId: string | number) => Promise<string>;
    suppressSelection: boolean;
    fallbackValue: String;
    lines: Number;
    colspan: Number;
    singleClickEdit: boolean;
    /** Width adjusment from flex columns */
    widthAdjustment: number = 0;
    headerMenuSlot?: Function;
    disableDistinct?: boolean;
    summarySlot?: Function;
    utc?: boolean;
    required?: boolean;
    exportMetaData: {
        linkUrl?: string
    } = {};
    autoHeight: boolean;
    headerClass: any;
    boundFields: string[];

    _width: number;
    _pinned: string;
    _hide: boolean;

    get name() {
        return this.field;
    }
    get caption() {
        return this.headerName ?? this.field ?? this.colId;
    }
    set shown(value) {
        this.hide = !value;
    }
    get shown() {
        return !this.hide;
    }

    get width() {
        return this._width;
    }
    set width(value) {
        this._width = value;
        this.trackChange('width', value);
    }

    get pinned() {
        return this._pinned;
    }
    set pinned(value) {
        this._pinned = value;
        this.trackChange('pinned', value);
    }

    get hide() {
        return this._hide;
    }
    set hide(value) {
        this._hide = value;
        this.trackChange('hide', value);
    }

    get widthIsNumber() { return typeof this.width === 'number'; }


    constructor(options: DataColumnOptions) {
        if (options.column) {
            logger.warn(`<OColumn column="${options.column}"/> recieved deprecated option 'column'. This makes the column unbound from the DataObject. If this is intended please change to 'colId', if this column should be bound to a DataObject then change this to 'field'`);
        }
        //--- Base ---
        if (!options.field && !options.name && !options.column && !options.colId) { throw new Error('DataColumn: colId parameter is required'); }

        if (!options.type) {
            options.type = "string";
        }

        this.distinct = null;

        this.field = options.field ?? options.name ?? options.column ?? options.colId;
        this.colId = options.colId ?? options['col-id'] ?? options['colid'] ?? this.field;
        this.boundFields = options.boundFields ?? [];

        this.type = options.type;
        if (options.distinct) {
            if (options.distinct.length === 0) {
                this.distinct = { columns: [this.field] };
            } else {
                this.distinct = {
                    columns: options.distinct.split(",").map(col => col.trim()),
                }
            }
        }

        if (options.disableDistinct) {
            this.disableDistinct = options.disableDistinct;
        }

        this.order = options.order ?? null;
        if (typeof this.order === 'string') { this.order = parseFloat(this.order) || null; }
        this.suppressNavigable = options.suppressNavigable ?? false;
        this.suppressSelection = options.suppressSelection ?? false;

        //--- Display ---
        this._hide = options.hide ?? false;
        this.hideFromChooser = options.hideFromChooser ?? false;
        this.utc = options.utc ?? false;
        this.autoHeight = options.autoHeight ?? false;

        //--- Editing ---
        this.editable = options.editable ?? false;
        this.singleClickEdit = options.singleClickEdit ?? true;

        this.cellEditor = options.cellEditor ?? options['celleditor'];
        if (!this.cellEditor) {
            // this.cellEditor = 'OInputEditor';
            switch (options.type) {
                case "number":
                    this.cellEditor = "ONumberEditor";
                    break;
                case "date":
                case "datetime":
                case "time":
                    this.cellEditor = "ODateEditor"
                    break;
                case "bit":
                case "boolean":
                    this.cellEditor = "OBitEditor";
                    break;
                default:
                    this.cellEditor = "OTextEditor";
            }
        }
        if (typeof this.cellEditor === 'object') {
            this.cellEditor = markRaw(this.cellEditor);
        }

        //--- Filter ---
        this.filter = options.filter ?? 'OFilter';
        this.filterField = options.filterField ?? undefined;
        this.filterDropdown = options.filterdropdown ?? options.filterDropdown ?? 'OFilterDropdown';
        if (typeof this.filterDropdown === 'object') {
            this.filterDropdown = markRaw(this.filterDropdown);
        }
        this.filterSlot = options.filterSlot;


        this.filterParams = this._parseFilterParams(options.filterParams);


        this.sortField = options.sortField ?? undefined;
        this.defaultSortDirection = options.defaultSortDirection ?? options.type == "number" ? 'desc' : 'asc';


        //--- Header ---
        if (options.headerName != null) {
            this.headerName = `${options.headerName}`;
        }
        this.headerTitle = options.headerTitle;

        this.headerTextSlot = options.headerTextSlot;
        this.headerClass = options.headerClass;

        //--- Rendering and Styling ---
        this.cellClass = options.cellClass ?? options.class;
        this.classFn = options.classFn;
        this.cellStyle = options.cellStyle ?? options.style;
        this.cellStyles = options.cellStyles ?? [];
        this.editorClass = options.editorClass;
        if (options.cellTitle) {
            this.cellTitle = options.cellTitle;
        } else if (options.field) {
            // this.cellTitle = (pRow: any) => utils.format(pRow[this.field], this);
        }
        this.cellRenderer = options.cellRenderer || options['cellrenderer'];
        this.overlaySlot = options.overlaySlot;
        this.required = options.required ?? false;
        this.summarySlot = options.summarySlot;

        this.contextmenuTopSlot = options.contextmenuTopSlot;
        this.contextmenuBottomSlot = options.contextmenuBottomSlot;

        if (!this.cellRenderer) {
            switch (options.type) {
                case "bit":
                case "boolean":
                    this.cellRenderer = "OCheck";
                    break;
                default:
                    this.cellRenderer = null;
            }
        }

        if (this.cellRenderer && typeof this.cellRenderer === 'object') {
            this.cellRenderer = markRaw(this.cellRenderer);
        }


        this.cellRendererParams = options.cellRendererParams || options['cellrendererparams'];
        this.cellEditorParams = options.cellEditorParams || options['celleditorparams'];
        if (!this.cellEditorParams && options.type === 'time') {
            this.cellEditorParams = {
                timepickerOnly: true
            };
        }

        if (typeof options.aggregateSlot === 'function') {
            this.aggregateSlot = options.aggregateSlot;
        }

        //--- Sort ---
        this.sortable = options.sortable ?? false;
        this.sort = options.sort;

        //--- Width ---
        //console.log("setting width here", this.type, this.getDefaultWidthForColumn(this.type));

        this._width = options.width ?? this.getDefaultWidthForColumn(this.type);
        if (typeof this.width === 'string') {
            const parsedWidth = parseFloat(this.width);
            if (isNaN(parsedWidth)) {
                logger.warn(`[OColumn ${this.colId}] The provided width ${options.width} is not a number`);
                this._width = this.getDefaultWidthForColumn(this.type);
            } else {
                this._width = parsedWidth;
            }
            this._width = parseFloat(this.width) || this.getDefaultWidthForColumn(this.type);
        }
        this.minWidth = options.minWidth ?? 50;
        this.maxWidth = options.maxWidth;
        if (options.resizable != null) {
            console.warn(`OColumn ${this.colId}: prop resizable is deprecated in favor of disableResize`)
        }
        this.resizable = (options.resizable != null)
            ? options.resizable ?? true
            : !options.disableResize;

        this.suppressMovable = options.suppressMovable ?? false;

        //--- Pinned ---
        this._pinned = options.pinned;
        this.lockPinned = options.lockPinned;

        //--- Groups ---
        this.parentGroupId = options.parentGroupId;

        //--- NON STANDARD PROPS --- (properties not used by ag)
        this.getCopyValue = options.getCopyValue;
        this.getPasteValue = options.getPasteValue;
        this.overwriteCopy = options.overwriteCopy;
        this.overwritePaste = options.overwritePaste;

        if (options.summaryAggregate) {
            this.summaryAggregate = typeof options.summaryAggregate === 'string'
                ? options.summaryAggregate.toUpperCase()
                : options.summaryAggregate;
        }
        this.groupAggregate = options.groupAggregate ? options.groupAggregate.toUpperCase() : undefined;
        this.groupPathMode = options.groupPathMode ?? false;
        if (options.groupAggregateFn) {
            this.groupAggregateFn = options.groupAggregateFn;
            this.groupAggregate = 'CUSTOM';
        }
        this.groupPathField = options.groupPathField;
        this.groupPathReplacePlaceholder = options.groupPathReplacePlaceholder;
        if (options.summaryRound != null) {
            this.summaryRound = typeof options.summaryRound === 'string' ? parseInt(options.summaryRound) : options.summaryRound;
        }
        this.summaryFormat = options.summaryFormat;

        // Datagrid Navigation overrides
        this.dblclickHandler = options.dblclickHandler;

        this.left = options.left ?? null;//left will be used to have calculated position
        this.flexWidth = options.flexWidth ?? options['flexwidth'] // Width percentage or 'auto' used in auto-size mode
        if (this.flexWidth) {
            this.flexWidth = parseInt(this.flexWidth);
        } else {
            this.flexWidth = 0;
        }

        if (options.menu != null) {
            console.warn(`OColumn ${this.colId}: prop menu is deprecated in favor of disableMenu`)
        }
        this.menu = (options.menu != null)
            ? options.menu ?? true
            : !options.disableMenu;

        this.sortOrder = options.sortOrder ?? null;
        this.format = options.format;
        if (!this.format) {
            switch (this.type) {
                case 'date':
                    this.format = 'Short Date';
                    break;
                case 'datetime':
                    this.format = 'General Date Short Time';
                    break;
                case 'time':
                    this.format = 'Short Time';
                    break;
            }
        }

        this.cellRenderSlot = options.cellRenderSlot; // Cell render function passed thorugh OColumn slot
        this.cellEditorSlot = options.cellEditorSlot; // Editor render function passed thorugh OColumn slot

        this.headerMenuSlot = options.headerMenuSlot

        this.bulkUpdate = options.bulkUpdate ?? options['bulkupdate'];
        this.bulkUpdateEditorSlot = options.bulkUpdateEditorSlot;
        this.bulkUpdateApplyFunction = options.bulkUpdateApplyFunction;

        this.propertyChanges = {};

        this.unbound = !!(options.column || options.colId) && (!options.field || options.field.includes('.'));

        if (this.unbound) {
            this.sortable = false;
            const hasEditor = !!(options.cellEditorSlot || (options.cellEditor ?? options['celleditor']));
            this.editable = hasEditor ? options.editable ?? false : false;
            this.filter = options.filter;
            // if (!this.headerName) { this.headerName = this.name; }
        }

        // Export meta data
        if (options.cellRenderer === 'OLink' && options.cellRendererParams) {
            if (options.cellRendererParams.constructor === String) {
                this.exportMetaData.linkUrl = options.cellRendererParams;
            } else if (typeof options.cellRendererParams?.href === 'string') {
                this.exportMetaData.linkUrl = options.cellRendererParams.href;
            }
        } else if (options.exportMetaData) {
            this.exportMetaData = options.exportMetaData;
        }

        this.fallbackValue = options.fallbackValue;

        this.lines = options.lines ?? 1;
        this.colspan = options.colspan ?? 12;

    }

    getDefaultFormatForColumn(pType: string) {
        switch (pType) {
            case 'date':
                return 'Short Date';
            case 'datetime':
                return 'General Date Short Time';
            case 'time':
                return 'Short Time';
        }
        return undefined;
    }

    getDefaultWidthForColumn(type: string) {
        let width = 200;
        switch (type) {
            case "datetime":
            case "time":
                width = 180;
                break;
            case "date":
                width = 110;
                break;
            case "bit":
            case "boolean":
            case "number":
                width = 80;
                break;
            default:
            // code block
        }
        return width;
    }
    // getId() { return this.field; }

    // toJSON() {
    //     return {
    //         field: this.field,
    //         hide: this.hide,
    //         width: this.width,
    //         // sort:this.sort,
    //         order: this.order,
    //         pinned: this.pinned ?? '',
    //         left: this.left
    //     }
    // }

    // Helpers
    get cellRendererIsFunction() { return typeof this.cellRenderer === 'function' && !this.cellRenderer?.['props']; }

    widthUpdateDebouncer = new Debouncer(100);

    trackChange(name: string, value: any) {
        if (this.colId.startsWith('o365_')) {
            if (this.colId != 'o365_MultiSelect' || name != 'hide') {
                return;
            }
        }
        if (typeof value === 'undefined') { value = null; }
        if (name === 'width' && this.updateSize != null) {
            this.widthUpdateDebouncer.run(() => {
                this.updateSize(value);
            })
        }
        this.propertyChanges[name] = value;
    }

    private _parseFilterParams(pParams?: FilterParams) {
        if (pParams && pParams.autocomplete && pParams.autocomplete.dataObject) {
            pParams.autocomplete.dataObjectId = pParams.autocomplete.dataObject.id;
            pParams.autocomplete.appId = pParams.autocomplete.dataObject.appId;
            delete pParams.autocomplete.dataObject;
        }
        return pParams;
    }

}

type FilterParams = {
    autocomplete?: {
        dataObject?: DataObject,
        dataObjectId?: string,
        appId?: string,
        existingValuesOnly?: boolean,
        field?: string,
        displayField?: string,
        valueField?: string,
        disable?: boolean
    },
};

export { DataColumn };